/*  
This stylesheet is also imported into landing-page.css
==>  /play-app/public/n/src/assets/playStylesheets/landing-page.css
*/
#complexity-tooltip {
  position: absolute;
  padding: 20px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #ecf0f2;
  transform-origin: bottom;
  transform: translateY(-80%);
  right: 0;
}
#complexity-tooltip.apply-now-password {
  top: -15px;
}
#complexity-tooltip.an-button-password {
  transform: translateY(-149%);
}

#complexity-tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -23px;
  right: 12px;
  /* left:50%; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1em solid #000;
  border-color: #fff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: 2px -2px 3px 0px rgba(0, 0, 0, 0.04);
}
#complexity-tooltip p {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.24px;
  color: #333333;
  margin-bottom: 8px;
}
.complexityRule {
  display: flex;
  align-items: center;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 1.82;
  letter-spacing: -0.12px;
  color: #677375;
}

.complexityRule.valid {
  color: #303637;
}
.complexityRule span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #d8d8d8;
  border-radius: 99px !important;
  margin-right: 10px;
  transition: all 0.3s ease;
}
.complexityRule span.valid {
  background-color: #10c302;
}

@media all and (max-width: 1000px) {
  #complexity-tooltip {
    right: 0;
    transform-origin: bottom;
    transform: translateY(-96%);
  }
  #complexity-tooltip.an-button-password {
    transform: translateY(-148%);
    -webkit-transform: translateY(-148%);
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  #complexity-tooltip {
    transform: translateY(-120%);
  }
}
